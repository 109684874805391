$(document).ready(function () {
  // wow initiation
  new WOW().init();

  // navigation bar toggle
  $('#navbar-toggler').click(function () {
    $('.navbar-collapse').slideToggle(400);
  });

  // navbar bg change on scroll
  $(window).scroll(function () {
    let pos = $(window).scrollTop();
    if (pos >= 100) {
      $('.navbar').addClass('cng-navbar');
    } else {
      $('.navbar').removeClass('cng-navbar');
    }
  });

  // faq accordion
  $('.faq-head').each(function () {
    $(this).click(function () {
      $(this).next().toggleClass('show-faq-content');
      let icon = $(this).children('span').children('i').attr('class');

      if (icon == 'fas fa-plus') {
        $(this).children('span').html('<i class = "fas fa-minus"></i>');
      } else {
        $(this).children('span').html('<i class = "fas fa-plus"></i>');
      }
    });
  });
});

//email clear form

window.onbeforeunload = () => {
  for (const form of document.getElementsByTagName('form')) {
    form.reset();
  }
};

// load button
$(document).ready(function () {
  var visibleCount = 6;
  var totalCount = $('.single-box').length;

  $('.single-box:lt(' + visibleCount + ')').show();

  $('#load-btn').click(function () {
    visibleCount = visibleCount + 6;
    if (visibleCount >= totalCount) {
      $('#load-btn').hide();
    }
    $('.single-box:lt(' + visibleCount + ')').show();
  });
});

// testimonial carousel
$('.testimonial .owl-carousel').owlCarousel({
  loop: true,
  autoplay: true,
  autoplayTimeout: 15000,
  dots: true,
  nav: false,
  items: 1,
});

// testimonial carousel
$('.testimonial2 .owl-carousel').owlCarousel({
  loop: true,
  autoplay: true,
  autoplayTimeout: 15000,
  dots: true,
  nav: false,
  items: 1,
});

if (typeof define === 'function' && define.amd) {
  define('jquery', [], function () {
    return jQuery;
  });
}

var // Map over jQuery in case of overwrite
  _jQuery = window.jQuery,
  // Map over the $ in case of overwrite
  _$ = window.$;

jQuery.noConflict = function (deep) {
  if (window.$ === jQuery) {
    window.$ = _$;
  }

  if (deep && window.jQuery === jQuery) {
    window.jQuery = _jQuery;
  }

  return jQuery;
};
